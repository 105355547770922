import React, {createRef, useState} from "react";
import {StyledMainContainer} from "./index.styles";
import {
  Section1,
  Section10,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8, Section9
} from "../../components";
import {Form, Grid, message} from "antd";

import {axiosConfig} from "../../config/axios.config";

import brandListJson from '../../assets/data/arrayOfBrands.json'
import servicesListJson from '../../assets/data/services.json'
import {MyModal} from "../../components/common/MyModal/MyModal";

const {useBreakpoint} = Grid;
export const Main = () => {
  const [form] = Form.useForm();

  const screens = useBreakpoint()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const recaptchaRef = createRef();

  const handleReCaptchaChange = (value) => {
    console.log("handleReCaptchaChange value",value)
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinishForm = (values) => {
    setLoading(true)
    const recaptchaVal = recaptchaRef.current.getValue();

    form.validateFields().then(() => {
          if (true) {
            return axiosConfig.post("/api/aurus/client-lead", {...values}).then((res) => {
              form.resetFields()
              setLoading(false)
              setIsModalOpen(!isModalOpen)
              return message.success({content: "Submitted"})
            }).catch((res) => {
              setLoading(false)
            })
          } else {
            setLoading(false)
            return message.error("Recaptcha error")
          }
        }
    )
  }

  const arrayOfBlocks = [
    <Section1 setIsModalOpen={setIsModalOpen}/>,
    <Section2 setIsModalOpen={setIsModalOpen}/>,
    <Section3 setIsModalOpen={setIsModalOpen}/>,
    <Section4/>,
    <Section5 setIsModalOpen={setIsModalOpen}/>,
    // <Section6/>,
    // <Section7 setIsModalOpen={setIsModalOpen}/>,
    <Section9/>,
    // <Section10/>,
  ]

  const brandList = brandListJson
  const servicesList = servicesListJson

  return (
    <StyledMainContainer>
      {arrayOfBlocks.map((item, index) => (
        <div key={index + "-site-section"}>
          {item}
        </div>
      ))}
      
      <MyModal screens={screens} form={form} loading={loading} isModalOpen={isModalOpen} handleOk={handleOk}
               handleCancel={handleCancel}
               onFinishForm={onFinishForm} servicesList={servicesList} brandList={brandList}
               recaptchaRef={recaptchaRef}
               handleReCaptchaChange={handleReCaptchaChange}/>
      
      <div id="paypal-container-5BEZCTSSW4JMA"></div>
    
    </StyledMainContainer>
  )
}